<!--
 * @Description: 楼盘
 * @Author: 琢磨先生
 * @Date: 2023-02-18 21:33:47
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2024-01-25 15:34:59
-->
<template>
  <el-card class="box query">
    <el-form :model="query" ref="query" :inline="true" @submit.prevent="onSearch">
      <el-form-item label="名称">
        <el-input
          v-model="query.q"
          placeholder="请输入楼盘名称"
          clearable
          style="width: 300px"
        ></el-input>
      </el-form-item>
      <el-form-item label="状态">
        <el-select
          v-model="query.status"
          placeholder
          clearable
          style="width: 120px"
        >
          <el-option
            :label="item.label"
            :value="item.value"
            v-for="item in enums.estate_status"
            :key="item.value"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="代理类型">
        <el-select
          v-model="query.type"
          placeholder
          clearable
          style="width: 120px"
        >
          <el-option
            :label="item.label"
            :value="item.value"
            v-for="item in enums.estate_types"
            :key="item.value"
          ></el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="隐藏客户手机">
        <el-select
          v-model="query.isHiddenMobile"
          placeholder=""
          clearable
          style="width: 120px"
        >
          <el-option label="是" :value="true"></el-option>
          <el-option label="否" :value="false"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="自定义公司">
        <el-select
          v-model="query.isHiddenCompany"
          placeholder=""
          clearable
          style="width: 120px"
        >
          <el-option label="是" :value="true"></el-option>
          <el-option label="否" :value="false"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="有二维码">
        <el-select
          v-model="query.isGetQRCode"
          placeholder=""
          clearable
          style="width: 120px"
        >
          <el-option label="是" :value="true"></el-option>
          <el-option label="否" :value="false"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="购房资格">
        <el-select
          v-model="query.isQualification"
          placeholder=""
          clearable
          style="width: 120px"
        >
          <el-option label="是" :value="true"></el-option>
          <el-option label="否" :value="false"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="详情下架">
        <el-select
          v-model="query.isSoldOut"
          placeholder=""
          clearable
          style="width: 120px"
        >
          <el-option label="是" :value="true"></el-option>
          <el-option label="否" :value="false"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="隐藏带看人手机">
        <el-select
          v-model="query.isHideDaikanMobile4"
          placeholder=""
          clearable
          style="width: 120px"
        >
          <el-option label="是" :value="true"></el-option>
          <el-option label="否" :value="false"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button
          type="primary"
          round
          @click="onSearch"
          icon="search" 
          native-type="submit"
          >查询</el-button
        >
      </el-form-item>
      <el-form-item label="">
        <el-button
          type="success"
          size="small"
          round
          :loading="createIndexLoading"
          @click="onCreateIndex"
          >创建索引</el-button
        >
      </el-form-item>
    </el-form>
  </el-card>

  <el-card class="box data" v-loading="loading">
    <el-table ref="table" :data="tableData.data" border stripe >
      <el-table-column
        label="名称"
        prop="name"
        fixed="left"
        width="200"
      ></el-table-column>
      <el-table-column label="ID" prop="id" width="80"></el-table-column>
      <el-table-column
        label="类型"
        prop="typeText"
        width="100"
      ></el-table-column>
      <el-table-column
        label="分部"
        prop="attributeName"
        width="100"
      ></el-table-column>
      <el-table-column label="市县/自定义区域" prop="areaName" width="340">
        <template #default="scope">
          <div>{{ scope.row.areaName }}</div>
          <div>{{ scope.row.udfAreaName }}</div>
        </template>
      </el-table-column>
      <el-table-column label="状态" width="100">
        <template #default="scope">
          <el-tag
            type="success"
            size="small"
            v-if="scope.row.stopStatus == 0"
            >{{ scope.row.stopStatusText }}</el-tag
          >
          <el-tag
            type="danger"
            size="small"
            v-else-if="scope.row.stopStatus == 2 || scope.row.stopStatus == 3"
            >{{ scope.row.stopStatusText }}</el-tag
          >
        </template>
      </el-table-column>
      <el-table-column label="报备设置" width="200">
        <template #default="scope">
          <span>
            需提前
            <span class="blue"
              >{{ scope.row.aheadHour }}小时{{ scope.row.aheadMin }}分</span
            >
          </span>
          <div>
            <span v-if="scope.row.limitNum">
              限
              <span class="blue">{{ scope.row.limitNum }}</span
              >人报备
            </span>
            <span class="red" v-else>不限人数(任何人可报)</span>
          </div>
          <div>
            <span v-if="scope.row.protectTheDay">
              报备保护只
              <span class="blue">当天有效</span>
            </span>
            <span v-else>
              <span
                class="red"
                v-if="scope.row.protectDays == 0 && scope.row.protectHour == 0"
                >无报备保护期</span
              >
              <span
                v-if="scope.row.protectDays != 0 || scope.row.protectHour != 0"
              >
                报备保护期
                <span class="blue"
                  >{{ scope.row.protectDays }}天{{
                    scope.row.protectHour
                  }}小时</span
                >
              </span>
            </span>
          </div>
          <div>
            <span v-if="scope.row.dkSecureDays">
              带看保护
              <span class="blue">{{ scope.row.dkSecureDays }}天</span>
            </span>
            <span v-else class="red">无带看保护期</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="自定义公司名称" width="200">
        <template #default="scope">
          <div v-if="scope.row.isHiddenCompany">
            是
            <span class="blue"
              >(替换公司:{{
                scope.row.copyCompanyName
                  ? scope.row.copyCompanyName
                  : "三亚德佑房地产投资服务有限公司"
              }})</span
            >
          </div>
          <div v-else-if="scope.row.companyPrefix">
            分销前缀(
            <span class="blue">{{ scope.row.companyPrefix }}</span
            >)
          </div>
        </template>
      </el-table-column>
      <el-table-column label="隐藏客户手机号" width="150">
        <template #default="scope">
          <div v-if="scope.row.isHiddenMobile">
            是
            <span class="blue">(隐藏中间{{ scope.row.hiddenNum }}位)</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="复制性别" width="100">
        <template #default="scope">
          <div v-if="scope.row.copySex">是</div>
        </template>
      </el-table-column>
      <el-table-column label="有带看单" width="100">
        <template #default="scope">
          <div v-if="scope.row.isUpDKTicket">是</div>
        </template>
      </el-table-column>
      <el-table-column label="需到案确认" width="100">
        <template #default="scope">
          <div v-if="scope.row.isYBConfirm">是</div>
        </template>
      </el-table-column>

      <el-table-column label="开启二维码" width="140">
        <template #default="scope">
          <div v-if="scope.row.isGetQRCode">
            是
            <span class="blue" v-if="scope.row.needReqQRCode"
              >(需手动获取)</span
            >
            {{
              scope.row.qrAfterMinutes
                ? `、获取间隔${scope.row.qrAfterMinutes}分钟`
                : ""
            }}
            {{
              scope.row.qrBeforeHouse
                ? `、报备${scope.row.qrBeforeHouse}小时后拒绝获取`
                : ""
            }}
          </div>
        </template>
      </el-table-column>
      <el-table-column label="需购房资格" width="100">
        <template #default="scope">
          <div v-if="scope.row.isQualification">是</div>
        </template>
      </el-table-column>
      <el-table-column label="详情下架" width="100">
        <template #default="scope">
          <span class="red" v-if="scope.row.isSoldOut">是</span>
        </template>
      </el-table-column>
      <el-table-column
        label="佣金点位"
        prop="fxPolicy"
        min-width="400"
      ></el-table-column>
      <el-table-column
        label="报备说明"
        prop="tuijianDesc"
        min-width="300"
      ></el-table-column>

      <el-table-column
        label="创建时间"
        width="180"
        prop="creationTime"
      ></el-table-column>
      <el-table-column label="操作" width="150" fixed="right">
        <template #default="scope">
          <el-button
            type="primary"
            link
            size="small"
            @click="onEdit(scope.row)"
            icon="edit"
            v-if="is_can_edit"
            >修改</el-button
          >
          <el-popconfirm
            width="220"
            v-if="is_can_del"
            :title="`您确定要删除【${scope.row.name}】？`"
            @confirm="onDelete(scope.row)"
          >
            <template #reference>
              <el-button
                size="small"
                type="danger"
                link
                icon="delete"
                :loading="scope.row.deleting"
                >删除</el-button
              >
            </template>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="pageSizeChange"
      @current-change="pageCurrentChange"
      :current-page="query.pageIndex"
      :page-sizes="[20, 40, 60, 80, 100]"
      :page-size="query.pageSize"
      layout="total, sizes, prev, pager, next"
      :total="tableData.counts"
    ></el-pagination>
  </el-card>
  <edit-loupan
    :id="edit_id"
    @closed="edit_id = null"
    @success="loadData"
  ></edit-loupan>
</template>

<script>
import common_api from "@/http/common_api";
import EditLoupan from "./edit_loupan.vue";
export default {
  components: {
    EditLoupan,
  },
  data() {
    return {
      loading: false,
      createIndexLoading: false,
      query: {
        pageIndex: 1,
        pageSize: 20,
        order: {
          sort: "",
          fieldName: "",
        },
        q: "",
      },
      enums: {},
      tableData: {
        counts: 0,
      },
      edit_id: null,
      is_can_edit: false,
      is_can_del: false,
    };
  },
  created() {
    this.is_can_edit = this.$power("admin/v1/estate/edit");
    this.is_can_del = this.$power("admin/v1/estate/del");
    common_api.get_enums().then((res) => {
      if (res.code == 0) {
        this.enums = res.data;
      }
    });
    this.loadData();
  },
  methods: {
    /**
     * 搜索
     */
    onSearch() {
      this.query.pageIndex = 1;
      this.loadData();
    },
    /**
     * 分页页数更改
     */
    pageSizeChange(val) {
      this.query.pageSize = val;
      this.loadData();
    },

    /**
     * 分页页码更改
     */
    pageCurrentChange(val) {
      this.query.pageIndex = val;
      this.loadData();
    },

    /**
     * 排序
     */
    onSort(e) {
      if (e.prop) {
        this.query.sort = {
          order: e.order,
          prop: e.prop,
        };
        this.loadData();
      }
    },

    /**
     *
     */
    loadData() {
      this.loading = true;
      this.$http
        .post("admin/v1/estate", this.query)
        .then((res) => {
          if (res.code == 0) {
            this.tableData = res.data;
          }
        })
        .finally(() => (this.loading = false));
    },

    /**
     * 创建索引
     */
    onCreateIndex() {
      this.$confirm("您确定要重新创建？", "温馨提醒").then(() => {
        this.createIndexLoading = true;
        this.$http.get("admin/v1/estate/createIndex").then((res) => {
          if (res.code == 0) {
            this.$message.success(res.msg);
          }
          this.createIndexLoading = false;
        });
      });
    },

    /**
     * 修改
     */
    onEdit(item) {
      this.edit_id = item.id;
    },

    /**
     * 删除
     */
    onDelete(item) {
      item.deleting = true;
      this.$http.get(`admin/v1/estate/del?id=${item.id}`).then((res) => {
        if (res.code == 0) {
          this.$message.success(res.msg);
          this.loadData();
        }
      });
    },
  },
};
</script>

<style scoped>
/* .blue {
  color: var(--el-color-primary);
}
.red {
  color: var(--el-color-danger);
} */
</style>
